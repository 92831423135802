import React from 'react'
import { ImCross } from "react-icons/im";
import { PrimaryMenu } from '../../json/Menus';
import { useNavigate } from 'react-router-dom';

const MobileMenu = ({ setSidebar, sidebar }) => {
    const navigate = useNavigate();

    const handleClick = (url)=>{
        navigate(url);
        setSidebar(false);
    }

    return (
        <div>
            <div className={`${!sidebar && 'hidden'} fixed inset-0 bg-black opacity-25 z-[48]`}
                onClick={() => setSidebar(false)}
            />
            <div className={`lg:hidden z-[50] fixed mobile-menu ${sidebar ? 'open' : ''}  pt-6 px-10 top-0 bottom-0 bg-gray-800 text-white`}>
                <p className='flex mb-10'><span onClick={() => setSidebar(false)} className='ml-auto -mr-6 cursor-pointer'><ImCross /></span></p>
                {PrimaryMenu?.map((item) => (
                    <p key={item?.Name} className='mb-6 text-sm cursor-pointer'><span onClick={()=>handleClick(item?.Url)}>{item?.Name}</span></p>
                ))}
            </div>
        </div>
    )
}

export default MobileMenu

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const studentApiSlice = createApi({
    reducerPath: 'studentApi',
    baseQuery: fetchBaseQuery({ 
        baseUrl: apiUrl, 
        credentials: 'include',
     }),

    endpoints: (builder) => ({

        register: builder.mutation({
            query: (formData) => ({
                url: `/student/register`,
                method: "POST",
                body: formData,
            }),
        }),

        login: builder.mutation({
            query: (data) => ({
                url: `/student/login`,
                method: 'POST',
                body: data
            }),
        }),

        refresh: builder.query({
            query: () => ({
                url: '/student/refresh',
                method: 'GET',
            })
        }),

        

    }),
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useRefreshQuery
} = studentApiSlice;

export const studentApiReducer = studentApiSlice.reducer;


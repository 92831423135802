import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useFetchAllReviewsQuery,
  useFetchCollegesBySlugQuery,
  useFetchCollegesPostAllQuery,
} from "../store/slice/CollegeSlice";

const storage = process.env.REACT_APP_S3_BUCKET;

const CollegeView = () => {
  window.scroll(0, 0);
  const navigate = useNavigate();
  const { slug } = useParams();
  const { data: collegeData } = useFetchCollegesBySlugQuery(slug);
  const collegeName = collegeData?.data[0]?.name;
  const collegeId = collegeData?.data[0]?._id;
  const { data: tabsData } = useFetchCollegesPostAllQuery(collegeName);
  const College = collegeData?.data[0];
  const { data: response } = useFetchAllReviewsQuery(collegeId);
  const reviews = response?.reviews || [];
  const totalReviews = response?.total || reviews.length;

  const CollegeImage = storage + College?.coverimage;

  const [activeTab, setActiveTab] = useState("Basic Info");

  useEffect(() => {
    if (collegeData && collegeData.data && collegeData.data[0]) {
      setActiveTab("Basic Info");
    }
  }, [collegeData]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const renderTabContent = () => {
    if (activeTab === "Basic Info") {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: College?.content }}
          className="htmlContent"
        />
      );
    }

    const activeTabContent = tabsData?.data.find(
      (tab) => tab.tabName === activeTab
    );
    return (
      <div
        dangerouslySetInnerHTML={{ __html: activeTabContent?.content }}
        className="htmlContent"
      />
    );
  };



  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${CollegeImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="min-h-[400px] bg-cover bg-center flex justify-center items-center"
      >
        <h1 className="text-white text-4xl font-bold bg-black bg-opacity-50 px-6 py-3 rounded-lg text-center">
          {College?.name}
        </h1>
      </div>

      <div className="max-w-6xl -mt-16 mx-auto bg-white shadow-lg rounded-lg">
        <div className="p-6">
          <div className="flex flex-col sm:flex-row items-center">
            <img
              src={`${storage}${College?.logo}`}
              style={{ borderRadius: "50%", width: "100px", height: "100px" }}
              alt={College?.name}
              className="mb-4 sm:mb-0 sm:mr-6"
            />
            <div className="text-center sm:text-left">
              <h2 className="text-3xl font-bold text-gray-800">
                {College?.name}
              </h2>
              <div className="mt-2">
                {typeof College?.location[0] === "string" ? (
                  <span className="bg-gray-200 px-4 py-2 mr-2 rounded-lg">
                    {College?.location[0]}
                  </span>
                ) : (
                  <span className="bg-gray-200 px-4 py-2 mr-2 rounded-lg">
                    {College?.location[0].city +
                      "," +
                      College?.location[0].state}
                  </span>
                )}

                <span className="bg-gray-200 px-4 py-2 rounded-lg">
                  {College?.subtitle}
                </span>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row mt-4 mx-auto sm:mt-0 items-center sm:space-x-4 space-y-2 sm:space-y-0">
              <div>
                {/* <button className="bg-red-500 text-white px-4 py-2 rounded-lg transition-transform transform hover:scale-105 mr-2">
                  Brochure
                </button>
                <button className="bg-blue-500 text-white px-4 py-2 rounded-lg transition-transform transform hover:scale-105">
                  Enquire
                </button> */}
              </div>

             
             
            </div>
          </div>

          <div className="flex flex-col mt-4 sm:flex-row sm:space-x-4">
            <div
              className={` rounded py-1 px-4 mb-2 cursor-pointer ${
                activeTab === "Basic Info"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 shadow"
              }`}
              onClick={() => handleTabClick("Basic Info")}
            >
              <h3 className="text-md font-semibold">Basic Info</h3>
            </div>
            {tabsData?.data.map((tab, index) => (
              <div
                key={index}
                className={`rounded py-1 px-4 mb-2 cursor-pointer ${
                  activeTab === tab.tabName
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 shadow"
                }`}
                onClick={() => handleTabClick(tab.tabName)}
              >
                <h3 className="text-md font-semibold">{tab.tabName}</h3>
              </div>
            ))}
            
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto mb-10">
        <div className="border border-gray-300 rounded mt-10 p-6 bg-white shadow-md">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default CollegeView;

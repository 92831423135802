export const PrimaryMenu = [
    {
        Name: 'UG',
        Url: '/article/colleges/courses/UG Courses/6595578ebed688263641db9d',
        // SubMenu: [
        //     {
        //         Name: 'Engineering Courses',
        //         Url: '/engineering-colleges',
        //         SubMenu: [
        //             { Name: 'Computer Engineering', Url: '/engineering-colleges/computer-engineering' },
        //             { Name: 'Mechanical Engineering', Url: '/engineering-colleges/mechanical-engineering' },
        //             { Name: 'Civil Engineering', Url: '/engineering-colleges/civil-engineering' },
        //             { Name: 'Electrical Engineering', Url: '/engineering-colleges/electrical-engineering' },
        //             { Name: 'Electronics Engineering', Url: '/engineering-colleges/electronics-engineering' },
        //             { Name: 'Chemical Engineering', Url: '/engineering-colleges/chemical-engineering' },
        //             { Name: 'Aerospace Engineering', Url: '/engineering-colleges/aerospace-engineering' },
        //             { Name: 'Biomedical Engineering', Url: '/engineering-colleges/biomedical-engineering' },
        //             { Name: 'Environmental Engineering', Url: '/engineering-colleges/environmental-engineering' },
        //             { Name: 'Software Engineering', Url: '/engineering-colleges/software-engineering' },
        //             { Name: 'Petroleum Engineering', Url: '/engineering-colleges/petroleum-engineering' },
        //             { Name: 'Agricultural Engineering', Url: '/engineering-colleges/agricultural-engineering' },
        //             { Name: 'Industrial Engineering', Url: '/engineering-colleges/industrial-engineering' },
        //             { Name: 'Marine Engineering', Url: '/engineering-colleges/marine-engineering' },
        //             { Name: 'Metallurgical Engineering', Url: '/engineering-colleges/metallurgical-engineering' }
        //         ]
        //     },
        //     {
        //         Name: 'Medical Courses',
        //         Url: '/medical-colleges',
        //         SubMenu: [
        //             { Name: 'MBBS', Url: '/medical-colleges/mbbs' },
        //             { Name: 'BDS', Url: '/medical-colleges/bds' },
        //             { Name: 'BAMS', Url: '/medical-colleges/bams' },
        //             { Name: 'BHMS', Url: '/medical-colleges/bhms' },
        //             { Name: 'BUMS', Url: '/medical-colleges/bums' },
        //             { Name: 'BPT', Url: '/medical-colleges/bpt' },
        //             { Name: 'B.Sc Nursing', Url: '/medical-colleges/bsc-nursing' },
        //             { Name: 'B.V.Sc', Url: '/medical-colleges/bvsc' },
        //             { Name: 'B.Optom', Url: '/medical-colleges/boptom' },
        //             { Name: 'B.Pharma', Url: '/medical-colleges/bpharma' },
        //             { Name: 'BMLT', Url: '/medical-colleges/bmlt' },
        //             { Name: 'BOT', Url: '/medical-colleges/bot' },
        //             { Name: 'BASLP', Url: '/medical-colleges/baslp' },
        //             { Name: 'B.Sc Radiology', Url: '/medical-colleges/bsc-radiology' },
        //             { Name: 'B.Sc Cardiology', Url: '/medical-colleges/bsc-cardiology' }
        //         ]
        //     },
        //     {
        //         Name: 'Law Courses',
        //         Url: '/law-colleges',
        //         // SubMenu: [
        //         //     { Name: 'LLB', Url: '/law-colleges/llb' },
        //         //     { Name: 'BA LLB', Url: '/law-colleges/ba-llb' },
        //         //     { Name: 'BBA LLB', Url: '/law-colleges/bba-llb' },
        //         //     { Name: 'B.Sc LLB', Url: '/law-colleges/bsc-llb' },
        //         //     { Name: 'B.Com LLB', Url: '/law-colleges/bcom-llb' }
        //         // ]
        //     }
        // ]
    },
    {
        Name: 'PG',
        Url: '/article/colleges/courses/PG Courses/65a614fc20541e80817e597d',
        // SubMenu: [
        //     {
        //         Name: 'Engineering Colleges',
        //         Url: '/engineering-colleges',
        //         SubMenu: [
        //             { Name: 'M.Tech Computer Engineering', Url: '/engineering-colleges/mtech-computer-engineering' },
        //             { Name: 'M.Tech Mechanical Engineering', Url: '/engineering-colleges/mtech-mechanical-engineering' },
        //             { Name: 'M.Tech Civil Engineering', Url: '/engineering-colleges/mtech-civil-engineering' },
        //             { Name: 'M.Tech Electrical Engineering', Url: '/engineering-colleges/mtech-electrical-engineering' },
        //             { Name: 'M.Tech Electronics Engineering', Url: '/engineering-colleges/mtech-electronics-engineering' },
        //             { Name: 'M.Tech Chemical Engineering', Url: '/engineering-colleges/mtech-chemical-engineering' },
        //             { Name: 'M.Tech Aerospace Engineering', Url: '/engineering-colleges/mtech-aerospace-engineering' },
        //             { Name: 'M.Tech Biomedical Engineering', Url: '/engineering-colleges/mtech-biomedical-engineering' },
        //             { Name: 'M.Tech Environmental Engineering', Url: '/engineering-colleges/mtech-environmental-engineering' },
        //             { Name: 'M.Tech Software Engineering', Url: '/engineering-colleges/mtech-software-engineering' },
        //             { Name: 'M.Tech Petroleum Engineering', Url: '/engineering-colleges/mtech-petroleum-engineering' },
        //             { Name: 'M.Tech Agricultural Engineering', Url: '/engineering-colleges/mtech-agricultural-engineering' },
        //             { Name: 'M.Tech Industrial Engineering', Url: '/engineering-colleges/mtech-industrial-engineering' },
        //             { Name: 'M.Tech Marine Engineering', Url: '/engineering-colleges/mtech-marine-engineering' },
        //             { Name: 'M.Tech Metallurgical Engineering', Url: '/engineering-colleges/mtech-metallurgical-engineering' }
        //         ]
        //     },
        //     {
        //         Name: 'Medical Colleges',
        //         Url: '/medical-colleges',
        //         SubMenu: [
        //             { Name: 'MD', Url: '/medical-colleges/md' },
        //             { Name: 'MS', Url: '/medical-colleges/ms' },
        //             { Name: 'M.Sc Nursing', Url: '/medical-colleges/msc-nursing' },
        //             { Name: 'M.Pharma', Url: '/medical-colleges/mpharma' },
        //             { Name: 'MPT', Url: '/medical-colleges/mpt' },
        //             { Name: 'M.V.Sc', Url: '/medical-colleges/mvsc' },
        //             { Name: 'M.Optom', Url: '/medical-colleges/moptom' },
        //             { Name: 'M.Sc Radiology', Url: '/medical-colleges/msc-radiology' },
        //             { Name: 'M.Sc Cardiology', Url: '/medical-colleges/msc-cardiology' },
        //             { Name: 'MS Orthopedics', Url: '/medical-colleges/ms-orthopedics' },
        //             { Name: 'MS General Surgery', Url: '/medical-colleges/ms-general-surgery' },
        //             { Name: 'MD Pediatrics', Url: '/medical-colleges/md-pediatrics' },
        //             { Name: 'MD Dermatology', Url: '/medical-colleges/md-dermatology' },
        //             { Name: 'MD Psychiatry', Url: '/medical-colleges/md-psychiatry' },
        //             { Name: 'MD Radiology', Url: '/medical-colleges/md-radiology' }
        //         ]
        //     },
        //     {
        //         Name: 'Law Colleges',
        //         Url: '/law-colleges',
        //         SubMenu: [
        //             { Name: 'LLM', Url: '/law-colleges/llm' },
        //             { Name: 'Diploma in Law', Url: '/law-colleges/diploma-law' },
        //             { Name: 'Ph.D. in Law', Url: '/law-colleges/phd-law' },
        //             { Name: 'Corporate Law', Url: '/law-colleges/corporate-law' },
        //             { Name: 'International Law', Url: '/law-colleges/international-law' }
        //         ]
        //     }
        // ]
    },
    { Name: 'Colleges', Url: '/allcolleges' },
    { Name: 'Certification/Diploma', Url: '/article/category/65a6151720541e80817e5991' },
    { Name: 'Internships', Url: '/comingSoon' },
    { Name: 'Scholarships', Url: '/article/category/65e091db7bf21f4035800225' },
  
];

import { BrowserRouter, Route, Routes } from "react-router-dom";
import {MainRoute} from "./json/MainRoute";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import PrivateRoute from "./Private";

function App() {

  return (
    <BrowserRouter>
      <Header />
      <Routes>
      <Route Component={PrivateRoute}>
        {MainRoute?.map((item,index) => (
          <Route path={item?.Url} Component={item?.Component}  key={index}/>
        ))}
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

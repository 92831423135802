import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const collegeApiSlice = createApi({
  reducerPath: "collegeApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),

  endpoints: (builder) => ({
    fetchAllColleges: builder.query({
      query: (list) => `/college/all/${list}`,
    }),
    fetchAllCollegesbyName: builder.query({
      query: () => `/college/all`,
    }),

    //fetching using query
    fetchAllForSearch: builder.query({
      query: (query) => `/college/search?search=${query}`,
    }),

    fetchCollegesBySlug: builder.query({
      query: (slug) => `/college/slug/${slug}`,
    }),

    fetchCollegesPostAll: builder.query({
      query: (collegeName) => `/college/post/byCollege/${collegeName}`,
    }),
    createReview: builder.mutation({
      query: (reviewData) => ({
        url: `/review/create`,
        method: "POST",
        body: reviewData,
      }),
    }),
    fetchAllReviews: builder.query({
      query: (collegeId) => `/review/college/${collegeId}`,
    }),
    fetchAllTheReviews: builder.query({
      query: () => `/review/all`,
    }),
   
    fetchAllCourses: builder.query({
      query: (collegeId) => `college/course/CollegeId/${collegeId}`,
    }),
    fetchAllSpeciliazationByCourse: builder.query({
      query: (courseId) => `college/specialization/course/${courseId}`,
    }),
    fetchCollegesByLocation:builder.query({
      query:(state,city)=>`/college/get/${state}/${city}`,
    })
  }),



})

export const {
  useFetchAllCollegesQuery,
  useFetchAllCollegesbyNameQuery,
  useFetchCollegesBySlugQuery,
  useFetchAllForSearchQuery,
  useFetchCollegesPostAllQuery,
  useCreateReviewMutation,
  useFetchAllReviewsQuery,
  useFetchAllCoursesQuery,
  useFetchAllSpeciliazationByCourseQuery,
  useFetchAllTheReviewsQuery,
  useFetchCollegesByLocationQuery
} = collegeApiSlice;

export const collegeApiReducer = collegeApiSlice.reducer;
